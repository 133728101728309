import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { fetchGet } from "../../services/data-service";
import { PendingLabBanner } from "../WarningBanner";
import Loading from "../Loading";

export default function ApprovedLabRoute({ children }) {
    const { labId } = useParams();
    const [access, setAccess] = useState("loading");
    const { user, loading } = UserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const getLab = async () => {
            const lab = await fetchGet(`/labs/${labId}`, false);
            // If lab does not exist or if user is not the PI and lab is pending, redirect to 404 page
            if (lab) {
                if (lab.status !== "approved") {
                    if (user?.uid !== lab.piId) {
                        navigate("/page-not-found");
                    } else {
                        setAccess("pending");
                    }
                } else {
                    setAccess("approved");
                }
            }
        };

        if (loading) return;
        getLab();
    }, [navigate, user, labId, loading]);

    if (access === "loading") return <Loading />;
    if (access === "pending")
        return (
            <>
                <PendingLabBanner />
                {children}
            </>
        );
    if (access === "approved") return children;
}
