import { useState } from "react";
import { UserAuth } from "../context/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import "../styles/components/WarningBanner.css";
import "../styles/Spinner.css";

export const VerifyEmailBanner = () => {
    const { user, sendVerificationEmail } = UserAuth();

    const EmailVerificationButton = () => {
        const [clicked, setClicked] = useState(false);

        const verifyEmail = () => {
            setClicked(true);
            sendVerificationEmail(user);
        };

        return clicked ? (
            <span>Please check your email!</span>
        ) : (
            <span
                className="warning-banner-clickable"
                onClick={() => verifyEmail()}
            >
                Click here to resend verification email.
            </span>
        );
    };

    if (user && !user.emailVerified) {
        return (
            <div className="warning-banner">
                <ErrorOutlineIcon
                    style={{ fontSize: "16px", color: "black" }}
                />
                <span>Please verify your email to use all features!</span>
                <EmailVerificationButton />
            </div>
        );
    } else {
        return null;
    }
};

export const PendingLabBanner = () => {
    const { labId } = useParams();
    const navigate = useNavigate();
    return (
        <div className="warning-banner">
            <ErrorOutlineIcon style={{ fontSize: "16px", color: "black" }} />
            <span>
                Your lab is pending approval. You can edit your lab profile in
            </span>
            <span
                className="warning-banner-clickable"
                onClick={() => navigate(`/manager/${labId}`)}
            >
                Lab Manager.
            </span>
        </div>
    );
};
