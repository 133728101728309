import React, { useState, useEffect } from "react";
import { ModalComponent } from "../../components";
import { db, storage } from "../../firebase-config";
import { deleteDoc, doc, Timestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { UserAuth } from "../../context/auth-context";
import { Outlet } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { fetchGet, fetchPost, fetchPut } from "../../services/data-service";
import orcidLogo from "../../assets/orcid-image.png";
import { JatsParser } from "../../components";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from "@mui/icons-material";

const LabSelector = ({ setValue, watch, labs }) => {
    const selectedLabs = watch("labs") || [];

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div
            className="publication-manager-item-editor-input"
            onClick={handleClick}
            onMouseDown={handleClick}
        >
            <h4>Associated Labs</h4>
            <Autocomplete
                sx={{
                    "& .MuiOutlinedInput-root": {
                        padding: "0px",
                        // Add any other styles you need to override here
                    },
                    "& .MuiAutocomplete-input": {
                        fontSize: "14px",
                        padding: "8px",
                    },
                }}
                multiple
                options={labs}
                getOptionLabel={(option) => option.name || ""}
                value={Array.isArray(selectedLabs) ? selectedLabs : []}
                onChange={(event, newValue, reason) => {
                    if (event) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    const uniqueValues = Array.from(
                        new Set(newValue.map((lab) => lab.id))
                    ).map((id) => newValue.find((lab) => lab.id === id));
                    setValue("labs", uniqueValues);
                }}
                onClick={handleClick}
                onMouseDown={handleClick}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{
                            fontSize: "12px",
                            padding: "0px",
                        }}
                        variant="outlined"
                        placeholder="Select Labs"
                        error={false}
                        onClick={handleClick}
                        onMouseDown={handleClick}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                        }}
                    />
                )}
                slotProps={{
                    popper: {
                        onClick: handleClick,
                        onMouseDown: handleClick,
                    },
                    paper: {
                        onClick: handleClick,
                        onMouseDown: handleClick,
                    },
                }}
            />
        </div>
    );
};

const PublicationForm = ({ publication }) => {
    const {
        register,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useFormContext();
    const [labs, setLabs] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        const fetchLabs = async () => {
            const fetchedLabs = await fetchGet("/labs", false);
            fetchedLabs.forEach((lab) => {
                lab.overview = lab.overview[0].content;
            });
            setLabs(fetchedLabs);
        };
        fetchLabs();
    }, []);

    useEffect(() => {
        if (publication) {
            // Format the date safely
            let formattedDate = "";
            try {
                if (publication.publicationDate instanceof Timestamp) {
                    formattedDate = publication.publicationDate
                        .toDate()
                        .toISOString()
                        .split("T")[0];
                } else if (publication.publicationDate?._seconds) {
                    formattedDate = new Date(
                        publication.publicationDate._seconds * 1000
                    )
                        .toISOString()
                        .split("T")[0];
                } else if (typeof publication.publicationDate === "string") {
                    // Handle string date
                    const dateObj = new Date(publication.publicationDate);
                    formattedDate = dateObj.toISOString().split("T")[0];
                } else if (publication.publicationDate instanceof Date) {
                    formattedDate = publication.publicationDate
                        .toISOString()
                        .split("T")[0];
                }
            } catch (error) {
                console.error(
                    "Error formatting date:",
                    error,
                    publication.publicationDate
                );
            }

            // Find the selected labs using labIds
            const selectedLabs = labs.filter((lab) =>
                publication.labIds?.includes(lab.id)
            );

            // Use setValue for the date instead of including it in reset
            reset({
                title: publication.title || "",
                description: publication.description || "",
                authors: Array.isArray(publication.authors)
                    ? publication.authors.join(", ")
                    : publication.authors || "",
                doi: publication.doi || "",
                htmlLink: publication.htmlLink || "",
                journal: publication.journal || "",
                labs: selectedLabs,
                id: publication.id,
            });

            // Set the date separately using setValue
            if (formattedDate) {
                setValue("publicationDate", formattedDate);
            }

            // Set image preview if publication has images
            if (publication.images?.[0]) {
                setImagePreview(publication.images[0]);
            }
        } else {
            reset({
                title: "",
                description: "",
                authors: "",
                doi: "",
                htmlLink: "",
                publicationDate: "",
                journal: "",
                labs: [],
            });
            setImagePreview(null);
        }
    }, [publication, reset, labs, setValue]);

    // Update image preview when a new file is selected
    const watchImage = watch("image");
    useEffect(() => {
        if (watchImage?.[0]) {
            setImagePreview(URL.createObjectURL(watchImage[0]));
            return () => URL.revokeObjectURL(imagePreview);
        }
    }, [watchImage]);

    return (
        <form className="publication-manager-item-editor">
            <div className="publication-manager-item-editor-input">
                <h4>Publication Title</h4>
                <input
                    className={errors?.title && "input-error"}
                    type="text"
                    placeholder="Title of Article, Book, etc."
                    {...register("title", {
                        required: true,
                    })}
                />
                {errors?.title && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Authors</h4>
                <input
                    className={errors?.authors && "input-error"}
                    type="text"
                    name="authors"
                    placeholder="Authors"
                    {...register("authors", {
                        required: true,
                    })}
                />
                {errors?.authors && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            {/* Added Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Graphical Abstract</h4>
                <input
                    type="file"
                    accept="image/*"
                    {...register("image", {
                        validate: {
                            isImage: (files) => {
                                if (files.length === 0) return true; // Optional field
                                const file = files[0];
                                return (
                                    file.type.startsWith("image/") ||
                                    "Please upload a valid image."
                                );
                            },
                        },
                    })}
                />
                {/* Show either the new image preview or the existing image */}
                {imagePreview && (
                    <div className="image-preview">
                        <img src={imagePreview} alt="Preview" />
                    </div>
                )}
                {errors?.image && (
                    <ErrorMessages message={errors.image.message} />
                )}
            </div>
            {/* End of Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Short Description</h4>
                <textarea
                    className={errors?.description && "input-error"}
                    name="description"
                    placeholder="Simple description of the publication"
                    {...register("description", {
                        required: true,
                    })}
                />
                {errors?.description && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Publication Date</h4>
                <input
                    className={errors?.publicationDate && "input-error"}
                    type="date"
                    name="publicationDate"
                    label="Publication Date"
                    {...register("publicationDate", {
                        required: true,
                    })}
                />
                {errors?.publicationDate && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Journal</h4>
                <input
                    className={errors?.journal && "input-error"}
                    type="text"
                    name="journal"
                    placeholder="Journal Name"
                    {...register("journal", {
                        required: true,
                    })}
                />
                {errors?.journal && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>DOI</h4>
                <input
                    className={errors?.doi && "input-error"}
                    type="text"
                    name="doi"
                    placeholder="Full DOI Link"
                    {...register("doi", {
                        required: true,
                    })}
                />
                {errors?.doi && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Article HTML</h4>
                <input
                    className={errors?.htmlLink && "input-error"}
                    type="text"
                    name="htmlLink"
                    placeholder="Article HTML Link"
                    {...register("htmlLink", {
                        required: true,
                    })}
                />
                {errors?.htmlLink && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <LabSelector
                register={register}
                setValue={setValue}
                watch={watch}
                labs={labs}
            />
        </form>
    );
};

const PublicationItem = ({ publication, onSave, onDelete }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPublicationModalOpen, setIsPublicationModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const methods = useForm();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCloseEditor = () => {
        setIsPublicationModalOpen(false);
    };

    const handleSave = async (publication) => {
        const formData = methods.getValues();
        await onSave(publication, formData);
        setIsPublicationModalOpen(false);
    };

    const formatDisplayDate = (date) => {
        try {
            if (!date) return "Date not available";

            const formatWithTimezone = (d) => {
                // Set time to noon UTC to avoid timezone issues
                const adjusted = new Date(d);
                adjusted.setUTCHours(12);
                return adjusted.toLocaleDateString();
            };

            // If it's a string in YYYY-MM-DD format
            if (typeof date === "string") {
                const d = new Date(date);
                if (isNaN(d.getTime())) return "Date not available";
                return formatWithTimezone(d);
            }

            // If it's a Firestore Timestamp
            if (date instanceof Timestamp) {
                return formatWithTimezone(date.toDate());
            }

            // If it's a Firestore timestamp object with _seconds
            if (date?._seconds) {
                return formatWithTimezone(new Date(date._seconds * 1000));
            }

            // If it's a Date object
            if (date instanceof Date) {
                if (isNaN(date.getTime())) return "Date not available";
                return formatWithTimezone(date);
            }

            return "Date not available";
        } catch (error) {
            console.error("Error formatting date:", error);
            return "Date not available";
        }
    };

    return (
        <article className="publication-manager-item">
            <h2>{publication.title || "Paper"}</h2>
            <div className="publication-authors">
                {publication.authors.join(", ") || "Authors"}
            </div>
            {publication.images && publication.images[0] && (
                <div className="publication-image">
                    <img src={publication.images[0]} alt="Publication" />
                </div>
            )}
            <p className="publication-description">
                <JatsParser>
                    {publication.description ||
                        "Simple description of the article"}
                </JatsParser>
            </p>
            <div className="publication-meta">
                <div className="publication-date">
                    {`Published on ${formatDisplayDate(
                        publication.publicationDate
                    )}`}
                </div>
                <div className="my-publications-item-journal">
                    {publication.journal || "Journal"}
                </div>
                <div className="publication-links">
                    <a
                        href={publication.doi || "#"}
                        className="publication-doi"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {publication.doi ? `DOI: ${publication.doi}` : "DOI:"}
                    </a>
                    <div className="publications-manager-editor-left-buttons">
                        <a
                            href={publication.htmlLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Article HTML
                        </a>
                    </div>
                </div>
            </div>
            <div className="publications-manager-editor-buttons">
                <div className="publications-manager-editor-right-buttons">
                    <button onClick={() => setIsPublicationModalOpen(true)}>
                        <EditIcon style={{ fontSize: "20px" }} />
                    </button>
                    <button onClick={() => setIsDeleteModalOpen(true)}>
                        <DeleteIcon style={{ fontSize: "20px" }} />
                    </button>
                </div>
            </div>

            <ModalComponent
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
                title="Delete Publication"
                submitText="Delete"
                onSubmit={() => onDelete(publication.id)}
                isDeleteModal={true}
            >
                <p>Are you sure you want to delete this publication?</p>
            </ModalComponent>

            {!isMobile ? (
                <ModalComponent
                    isOpen={isPublicationModalOpen}
                    toggle={handleCloseEditor}
                    title="Edit Publication"
                    submitText="Save"
                    onSubmit={() => handleSave(publication)}
                >
                    <FormProvider {...methods}>
                        <PublicationForm publication={publication} />
                    </FormProvider>
                </ModalComponent>
            ) : (
                <>
                    <div
                        className={`publication-editor-overlay ${
                            isPublicationModalOpen ? "active" : ""
                        }`}
                        onClick={handleCloseEditor}
                    />
                    <div
                        className={`publication-editor-slideup ${
                            isPublicationModalOpen ? "active" : ""
                        }`}
                    >
                        <div className="publication-editor-card">
                            <button
                                className="publication-editor-close-button"
                                onClick={handleCloseEditor}
                            >
                                ×
                            </button>
                            <h2>Edit Publication</h2>
                            <FormProvider {...methods}>
                                <PublicationForm publication={publication} />
                            </FormProvider>
                            <div className="publication-editor-footer">
                                <button onClick={() => handleSave(publication)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </article>
    );
};

const MyPublications = () => {
    const [publicationsList, setPublicationsList] = useState([]);
    const [isAddingPublication, setIsAddingPublication] = useState(false);
    const methods = useForm();
    const { user } = UserAuth();

    useEffect(() => {
        const getData = async () => {
            const publications = await fetchGet(
                `/users/${user?.uid}/publications`,
                false
            );
            const sortedPublications = publications.sort((a, b) => {
                const getDate = (pub) => {
                    if (pub.publicationDate instanceof Timestamp) {
                        return pub.publicationDate.toDate();
                    }
                    if (pub.publicationDate?._seconds) {
                        return new Date(pub.publicationDate._seconds * 1000);
                    }
                    if (pub.publicationDate instanceof Date) {
                        return pub.publicationDate;
                    }
                    return new Date(pub.publicationDate);
                };

                return getDate(b) - getDate(a);
            });
            setPublicationsList(sortedPublications);
        };
        getData();
    }, [user]);

    const addPublication = async (data) => {
        if (await methods.trigger()) {
            const { authors, publicationDate, image, labs, ...rest } = data;
            const authorsList = authors
                .split(",")
                .map((author) => author.trim());

            // Fix timezone offset by setting the time to noon UTC
            const date = new Date(publicationDate);
            date.setUTCHours(12);

            const publication = {
                ...rest,
                authors: authorsList,
                publicationDate: date,
                labIds: labs ? labs.map((lab) => lab.id) : [],
                images: [],
                userId: user.uid,
                displayInProfile: false,
                creatorName: `${user.firstName} ${user.lastName}`,
                creatorId: user.uid,
            };

            // Send to backend
            const response = await fetchPost("/publications", publication);
            const newPublication = response.publication || response.data;

            // Handle image upload if present
            if (image && image[0]) {
                const imageRef = ref(
                    storage,
                    `publications/${newPublication.id}/image.jpg`
                );
                await uploadBytes(imageRef, image[0]);
                const downloadURL = await getDownloadURL(imageRef);

                await fetchPut(`/publications/${newPublication.id}`, {
                    images: [downloadURL],
                });

                publication.images = [downloadURL];
            }

            setPublicationsList((currentList) => {
                const updatedList = [
                    ...currentList,
                    { id: newPublication.id, ...publication },
                ];
                return updatedList.sort((a, b) => {
                    // Safe date comparison function
                    const getDate = (pub) => {
                        if (pub.publicationDate instanceof Timestamp) {
                            return pub.publicationDate.toDate();
                        }
                        if (pub.publicationDate?._seconds) {
                            return new Date(
                                pub.publicationDate._seconds * 1000
                            );
                        }
                        if (pub.publicationDate instanceof Date) {
                            return pub.publicationDate;
                        }
                        return new Date(pub.publicationDate);
                    };

                    return getDate(b) - getDate(a);
                });
            });
            setIsAddingPublication(false);
        }
    };

    const savePublication = async (publication, formData) => {
        if (await methods.trigger()) {
            const { authors, publicationDate, image, labs, ...rest } = formData;
            const updatedFields = {
                ...rest,
                authors: authors.split(",").map((author) => author.trim()),
                labIds: labs ? labs.map((lab) => lab.id) : [],
            };

            // Fix timezone offset by setting the time to noon UTC
            const date = new Date(publicationDate);
            date.setUTCHours(12);
            updatedFields.publicationDate = Timestamp.fromDate(date);

            // Handle image upload if present
            if (image && image[0]) {
                const imageRef = ref(
                    storage,
                    `publications/${publication.id}/image.jpg`
                );
                await uploadBytes(imageRef, image[0]);
                const downloadURL = await getDownloadURL(imageRef);
                updatedFields.images = [downloadURL];
            }

            // Update in Firestore
            await fetchPut(`/publications/${publication.id}`, updatedFields);

            // Update local state
            setPublicationsList((currentList) =>
                currentList.map((pub) =>
                    pub.id === publication.id
                        ? { ...pub, ...updatedFields }
                        : pub
                )
            );
        }
    };

    const deletePublication = async (id) => {
        await deleteDoc(doc(db, "Publications", id));
        setPublicationsList((currentList) =>
            currentList.filter((pub) => pub.id !== id)
        );
    };

    const connectORCID = () => {
        const clientId = process.env.REACT_APP_ORCID_CLIENT_ID;
        const redirectUri = "https://api.labgiant.ca/orcid-authentication";
        const scope = "/authenticate";
        const url = `https://orcid.org/oauth/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}`;
        window.open(url, "_self");
    };

    return (
        <div className="account-settings-section">
            <Outlet />
            <div className="account-settings-publications-header">
                <button
                    onClick={() => setIsAddingPublication(true)}
                    className="listings-manager-create-listing"
                >
                    <AddIcon />
                    Add Publication
                </button>
                <button
                    onClick={() => connectORCID()}
                    className="listings-manager-create-listing"
                >
                    <img
                        style={{
                            width: "24px",
                            height: "24px",
                        }}
                        src={orcidLogo}
                        alt="ORCID"
                    />
                    Connect ORCID
                </button>
            </div>

            <FormProvider {...methods}>
                <ModalComponent
                    isOpen={isAddingPublication}
                    toggle={() => setIsAddingPublication(false)}
                    title="Add Publication"
                    submitText="Add"
                    onSubmit={methods.handleSubmit(addPublication)}
                >
                    <PublicationForm methods={methods} />
                </ModalComponent>

                <div className="account-settings-publications-list">
                    {publicationsList.map((publication, index) => (
                        <PublicationItem
                            key={publication.id}
                            publication={publication}
                            onSave={savePublication}
                            onDelete={deletePublication}
                        />
                    ))}
                </div>
            </FormProvider>
        </div>
    );
};

export default MyPublications;
