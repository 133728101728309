import { fetchGet } from "./data-service";

export const ListingCacheParams = {
    staleTime: 20 * 1000 * 60,
    cacheTime: 30 * 1000 * 60,
};

export const fetchListings = async ({ queryKey }) => {
    try {
        const res = await fetchGet(`/listings`);
        for (const listing of res) {
            const { name } = await fetchGet(`/labs/${listing.labId}`, false);
            listing.labName = name;
        }
        return res;
    } catch (error) {
        console.error("Error fetching labs:", error);
        throw error;
    }
};

export const fetchListing = async ({ queryKey }) => {
    try {
        const [_, listingId] = queryKey;
        const res = await fetchGet(`/listings/${listingId}`);
        return res;
    } catch (err) {
        console.error("Error fetching listing:", err);
        throw err;
    }
};
