import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@stripe/stripe-js";
import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthContextProvider } from "./context/auth-context";
import { ChatContextProvider } from "./context/chat-context";
import { ShoppingCartContextProvider } from "./context/shoppingcart-context";
import { NotificationsContextProvider } from "./context/notifications-context";
import NavRoutes from "./components/navigation/NavRoutes";
import Navigation from "./components/navigation/Navigation";
import { NotificationsList } from "./components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const queryClient = new QueryClient();

const App = () => {
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <NotificationsContextProvider>
                        <ShoppingCartContextProvider>
                            <ChatContextProvider>
                                <div className="pageWrapper">
                                    <div className="navigation">
                                        <Navigation />
                                    </div>
                                    <div className="pageContent">
                                        <NavRoutes />
                                        <NotificationsList />
                                    </div>
                                </div>
                                <ScrollToTop />
                            </ChatContextProvider>
                        </ShoppingCartContextProvider>
                    </NotificationsContextProvider>
                </AuthContextProvider>
            </QueryClientProvider>
        </Router>
    );
};

export default App;
