import { fetchGet } from "./data-service";

export const UserCacheParams = {
    staleTime: 10 * 1000 * 60,
    cacheTime: 15 * 1000 * 60,
};

export const fetchUser = async ({ queryKey }) => {
    try {
        const [_, userId] = queryKey;
        const res = await fetchGet(`/users/${userId}`);
        return res;
    } catch (error) {
        console.error("Error fetching user:", error);
        throw error;
    }
};

export const fetchUserLabRequests = async ({ queryKey }) => {
    try {
        const [_, userId, { status }] = queryKey;
        const res = await fetchGet(
            `/users/${userId}/labRequests?status=${status}`
        );
        return res;
    } catch (error) {
        console.error("Error fetching lab requests:", error);
        throw error;
    }
};

export const fetchUserProjects = async ({ queryKey }) => {
    try {
        const [_, userId] = queryKey;
        const res = await fetchGet(`/users/${userId}/projects`);
        return res;
    } catch (error) {
        console.error("Error fetching projects:", error);
        throw error;
    }
};

export const fetchUserPublications = async ({ queryKey }) => {
    try {
        const [_, userId] = queryKey;
        const res = await fetchGet(`/users/${userId}/publications`);
        return res;
    } catch (error) {
        console.error("Error fetching publications:", error);
        throw error;
    }
};

export const fetchUserListings = async ({ queryKey }) => {
    try {
        const [_, userId] = queryKey;
        const res = await fetchGet(`/users/${userId}/listings`);
        return res;
    } catch (error) {
        console.error("Error fetching listings:", error);
        throw error;
    }
};
