import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import articlePlaceholder from "../../assets/article-placeholder.png";
import { LabProfileContext } from "../../context/lab-profile-context";
import { useQuery } from "@tanstack/react-query";
import { fetchLabPublications, LabCacheParams } from "../../services/lab-api";
import { Loading } from "../../components";
import { JatsParser } from "../../components";
import "../../styles/LabProfileTabs/Publications.css";

export const PublicationDetails = ({ publication }) => {
    return (
        <article className="lab-profile-publication-item">
            <h2 className="lab-profile-publication-title">
                {publication.title}
            </h2>
            <div className="lab-profile-publication-authors">
                {publication.authors.join(", ")}
            </div>
            {publication.images && publication.images.length > 0 && (
                <div className="lab-profile-publication-image">
                    <img src={publication.images[0]} alt="Publication" />
                </div>
            )}
            <p className="lab-profile-publication-description">
                <JatsParser>{publication.description}</JatsParser>
            </p>
            <div className="lab-profile-publication-meta">
                <div className="lab-profile-publication-date-journal">
                    Published on {publication.publicationDate} in
                    <span className="lab-profile-publication-journal">
                        {publication.journal}
                    </span>
                </div>
                <div className="lab-profile-publication-links">
                    <a
                        href={`https://doi.org/${publication.doi}`}
                        className="lab-profile-publication-doi"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        DOI: {publication.doi}
                    </a>
                    <div className="lab-profile-publications-editor-left-buttons">
                        <a
                            href={publication.htmlLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Article HTML
                        </a>
                    </div>
                </div>
            </div>
        </article>
    );
};

const Publications = () => {
    const { setSelectedPublication, setPublicationModalOpen } =
        useContext(LabProfileContext);
    const { labId } = useParams();
    const [showAllPublications, setShowAllPublications] = useState(false);

    const { data: publications, isLoading: publicationsLoading } = useQuery({
        queryKey: ["lab", labId, "publications"],
        queryFn: fetchLabPublications,
        enabled: !!labId,
        ...LabCacheParams,
    });
    const visiblePublications = useMemo(() => {
        if (!publications) return [];
        return showAllPublications ? publications : publications.slice(0, 3);
    }, [publications, showAllPublications]);

    if (publicationsLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="lab-profile-content">
                <div className="lab-profile-content-publications-section-wrapper">
                    <h3>Publications</h3>
                    {visiblePublications.length > 0 && (
                        <div className="publications-section">
                            {visiblePublications.map((pub, index) => (
                                <div
                                    key={index}
                                    className="publication-card"
                                    onClick={() => {
                                        setSelectedPublication(pub);
                                        setPublicationModalOpen(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    {pub.images && pub.images.length > 0 ? (
                                        <img
                                            src={pub.images[0]}
                                            alt={pub.title}
                                            className="profile-publication-image"
                                        />
                                    ) : (
                                        <img
                                            src={articlePlaceholder}
                                            alt={pub.title}
                                            className="profile-publication-image"
                                        />
                                    )}

                                    <div className="publication-details">
                                        <div>
                                            <h3>{pub.title}</h3>
                                            <p>{pub.authors.join(", ")}</p>
                                            <p>
                                                <JatsParser>
                                                    {pub.description}
                                                </JatsParser>
                                            </p>
                                            <p>
                                                Published on{" "}
                                                {pub.publicationDate} in
                                                <span className="publication-journal">
                                                    {pub.journal}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="publication-bottom">
                                            <a
                                                href={`https://doi.org/${pub.doi}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="doi"
                                            >
                                                DOI: {pub.doi}
                                            </a>
                                            <div className="publication-actions">
                                                <a
                                                    href={pub.htmlLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="html-button"
                                                >
                                                    Article HTML
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!showAllPublications && publications.length > 3 && (
                        <button
                            className="view-all-button"
                            onClick={() => setShowAllPublications(true)}
                        >
                            View more research ({publications.length})
                        </button>
                    )}
                    {showAllPublications && (
                        <button
                            className="view-less-button"
                            onClick={() => setShowAllPublications(false)}
                        >
                            View less
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
export default Publications;
