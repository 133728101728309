import { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Loading } from "../../components";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { fetchGet } from "../../services/data-service";
import "../../styles/LabSignup.css";

const libraries = ["places"];

const LabSettings = () => {
    const { labId } = useParams();
    const { register } = useForm();
    const [editing, setEditing] = useState(false);
    const [lab, setLab] = useState(null);
    const [sameAsLabAddressShipping, setSameAsLabAddressShipping] =
        useState(true);
    const [sameAsLabAddressBilling, setSameAsLabAddressBilling] =
        useState(true);
    const [editModeShippingBilling, setEditModeShippingBilling] =
        useState(false);
    const [editModeMiscellaneous, setEditModeMiscellaneous] = useState(false);

    // Dummy data for display
    useEffect(() => {
        const fetchLab = async () => {
            const lab = await fetchGet(`/labs/${labId}`);
            console.log(lab);
            setLab(lab);
        };
        fetchLab();
    }, [labId]);

    const dummyData = {
        labName: "Quantum Research Lab",
        labAddress: "123 Quantum Rd, Science City",
        institution: { label: "Quantum University", value: "quantum_uni" },
        faculty: { label: "Physics", value: "physics" },
        department: { label: "Quantum Mechanics", value: "quantum_mechanics" },
        areasOfResearch: "Quantum entanglement, Superposition",
        shippingAddress: "123 Quantum Rd, Science City",
        billingAddress: "123 Quantum Rd, Science City",
        labWebsite: "http://quantumlab.edu",
    };

    if (!lab) {
        return;
    }
    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
        >
            <div className="create-lab-page">
                <div className="create-lab-container">
                    <div className="create-lab-content">
                        <div
                            className="create-lab-section"
                            style={{ position: "relative" }}
                        >
                            <div className="header-with-button">
                                <h2 className="create-lab-subheader">
                                    Lab Information
                                </h2>
                                <button
                                    className="edit-button-shipping-billing"
                                    onClick={() => setEditing(!editing)}
                                >
                                    {editing ? "Save" : "Edit"}
                                </button>
                            </div>
                            <div className="create-lab-field">
                                <h3>Lab Name</h3>
                                {editing ? (
                                    <input
                                        className="input"
                                        type="text"
                                        defaultValue={lab.name}
                                    />
                                ) : (
                                    <p>{lab.name}</p>
                                )}
                            </div>
                            <div className="create-lab-field">
                                <h3>Lab Address</h3>
                                {editing ? (
                                    <input
                                        className="input"
                                        type="text"
                                        defaultValue={lab.addresses.lab}
                                    />
                                ) : (
                                    <p>{lab.addresses.lab}</p>
                                )}
                            </div>
                            <div className="create-lab-field">
                                <h3>Institution</h3>
                                {editing ? (
                                    <Select
                                        placeholder="Choose institution"
                                        defaultValue={lab.institutionName}
                                    />
                                ) : (
                                    <p>{lab.institutionName}</p>
                                )}
                            </div>
                            <div className="create-lab-field">
                                <h3>Faculty</h3>
                                {editing ? (
                                    <Select
                                        placeholder="Choose faculty"
                                        defaultValue={lab.facultyName}
                                    />
                                ) : (
                                    <p>{lab.facultyName}</p>
                                )}
                            </div>
                            <div className="create-lab-field">
                                <h3>Department</h3>
                                {editing ? (
                                    <Select
                                        placeholder="Choose department"
                                        defaultValue={lab.departmentName}
                                    />
                                ) : (
                                    <p>{lab.departmentName}</p>
                                )}
                            </div>
                            <div className="create-lab-field">
                                <h3>Areas of Research</h3>
                                {editing ? (
                                    <input
                                        className="input"
                                        placeholder="Keywords"
                                        defaultValue={lab.keywords.join(", ")}
                                    />
                                ) : (
                                    <p>{lab.keywords.join(", ")}</p>
                                )}
                            </div>
                        </div>
                        <div className="create-lab-section">
                            <div className="header-with-button">
                                <h2 className="create-lab-subheader">
                                    Shipping & Billing Information
                                </h2>
                                <button
                                    className="edit-button-shipping-billing"
                                    onClick={() =>
                                        setEditModeShippingBilling(
                                            !editModeShippingBilling
                                        )
                                    }
                                >
                                    {editModeShippingBilling ? "Save" : "Edit"}
                                </button>
                            </div>
                            {editModeShippingBilling ? (
                                <div>
                                    <div className="create-lab-field">
                                        <h3>Shipping Address</h3>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        sameAsLabAddressShipping
                                                    }
                                                    onChange={() =>
                                                        setSameAsLabAddressShipping(
                                                            !sameAsLabAddressShipping
                                                        )
                                                    }
                                                />
                                            }
                                            label="Same as lab address"
                                        />
                                        {!sameAsLabAddressShipping && (
                                            <StandaloneSearchBox>
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Enter shipping address"
                                                    {...register(
                                                        "shippingAddress"
                                                    )}
                                                    defaultValue={
                                                        dummyData.shippingAddress
                                                    }
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>Billing Address</h3>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        sameAsLabAddressBilling
                                                    }
                                                    onChange={() =>
                                                        setSameAsLabAddressBilling(
                                                            !sameAsLabAddressBilling
                                                        )
                                                    }
                                                />
                                            }
                                            label="Same as lab address"
                                        />
                                        {!sameAsLabAddressBilling && (
                                            <StandaloneSearchBox>
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Enter billing address"
                                                    {...register(
                                                        "billingAddress"
                                                    )}
                                                    defaultValue={
                                                        dummyData.billingAddress
                                                    }
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="create-lab-field">
                                        <h3>Shipping Address</h3>
                                        <p>Same as lab address</p>
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>Billing Address</h3>
                                        <p>Same as lab address</p>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="create-lab-section">
                            <div className="header-with-button">
                                <h2 className="create-lab-subheader">
                                    Miscellaneous
                                </h2>
                                <button
                                    className="edit-button-miscellaneous"
                                    onClick={() =>
                                        setEditModeMiscellaneous(
                                            !editModeMiscellaneous
                                        )
                                    }
                                >
                                    {editModeMiscellaneous ? "Save" : "Edit"}
                                </button>
                            </div>
                            <div className="create-lab-field">
                                <h3>Lab Website</h3>
                                {editModeMiscellaneous ? (
                                    <input
                                        className="input"
                                        type="text"
                                        defaultValue={dummyData.labWebsite}
                                    />
                                ) : (
                                    <p>{dummyData.labWebsite}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadScript>
    );
};

export default LabSettings;
