import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Groups as GroupsIcon,
    AccessTime as AccessTimeIcon,
    FmdGood as FmdGoodIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    Handshake as HandshakeIcon,
} from "@mui/icons-material";
import TestImage from "../assets/labgiantlady.png";
import { postNotification } from "../firebase/crud";
import { Loading } from "../components";
import { TypeBadge } from "../helpers/ListingHelpers";
import { db } from "../firebase-config";
import {
    collection,
    doc,
    addDoc,
    getDoc,
    setDoc,
    updateDoc,
    Timestamp,
} from "firebase/firestore";
import { UserAuth } from "../context/auth-context";
import { ModalComponent } from "../components";
import { useNavigate } from "react-router-dom";
import { sendEmailNotification } from "../firebase/crud";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@tanstack/react-query";
import { fetchLab, LabCacheParams } from "../services/lab-api";
import { fetchListing, ListingCacheParams } from "../services/listing-api";
import "../styles/Listing.css";

const Listing = () => {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const { listingId } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [shippingOption, setShippingOption] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [dynamicPrice, setDynamicPrice] = useState(null);
    const [dynamicQuantity, setDynamicQuantity] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [currentImgIdx, setCurrentImgIdx] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    const { data: listing, isLoading: listingLoading } = useQuery({
        queryKey: ["listing", listingId],
        queryFn: fetchListing,
        enabled: !!listingId,
        ...ListingCacheParams,
    });

    const { data: lab, isLoading: labLoading } = useQuery({
        queryKey: ["lab", listing?.labId, { banner: false }],
        queryFn: fetchLab,
        enabled: !!listing?.labId,
        ...LabCacheParams,
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (listing) {
            if (listing.isCollaborative && listing.type !== "digital-good") {
                setDynamicPrice(listing.price?.value);
                setDynamicQuantity(listing.quantity);
            }
            setMessage(
                `Hi, I would like to enquire some information about this listing: ${listing.title}`
            );
        }
    }, [listing]);

    const updatePriceAndQuantity = () => {
        if (!listing.variations || listing.type === "digital-good") return;

        let newPrice = listing.price?.value;
        let newQuantity = 99999;

        const { variants, combinedVariants, uncombinedVariants } =
            listing.variations;

        if (combinedVariants) {
            console.log("variants", variants.length);
            console.log("selected", Object.keys(selectedOptions).length);
            if (
                variants.length === 2 &&
                Object.keys(selectedOptions).length === variants.length
            ) {
                const [variant1, variant2] = variants;

                const variant1Index = variant1.options.findIndex(
                    (option) => option === selectedOptions[variant1.name]
                );
                const variant2Index = variant2.options.findIndex(
                    (option) => option === selectedOptions[variant2.name]
                );

                const { price, quantity } =
                    combinedVariants.options1[variant1Index].options2[
                        variant2Index
                    ];
                if (price) {
                    newPrice = price;
                }
                if (quantity) {
                    newQuantity = quantity;
                }
            }
        } else if (uncombinedVariants) {
            // @TODO: Handle uncombined variations
        }

        setDynamicPrice(newPrice);
        setDynamicQuantity(newQuantity);
    };

    const handleOptionChange = (variantName, option) => {
        setSelectedOptions((prev) => ({ ...prev, [variantName]: option }));
    };

    useEffect(() => {
        if (listing && selectedOptions) {
            updatePriceAndQuantity();
        }
    }, [selectedOptions]);

    const decreaseQuantity = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
        }
    };

    const increaseQuantity = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };

    const decreaseImgIdx = () => {
        if (currentImgIdx > 0) {
            setCurrentImgIdx(currentImgIdx - 1);
        } else {
            setCurrentImgIdx(listing.imgs.length - 1);
        }
    };

    const increaseImgIdx = () => {
        if (currentImgIdx < listing.imgs.length - 1) {
            setCurrentImgIdx(currentImgIdx + 1);
        } else {
            setCurrentImgIdx(0);
        }
    };

    const handleShippingOptionChange = (event) => {
        setShippingOption(event.target.name);
    };

    const sendListingRequest = async () => {
        const listingRequestsRef = collection(db, "ListingRequests");
        const listingRequestData = {
            requesterId: user.uid,
            type: listing.type,
            labId: listing.labId,
            listingId: listingId,
            listerId: listing.labMember.id,
            price: {
                value: listing.price.value,
                units:
                    listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units,
            },
            quantity: {
                value: quantity,
                units:
                    listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units,
            },
            status: {
                value: "Pending",
                createdAt: new Date(),
                updatedAt: new Date(),
            },
        };
        try {
            await addDoc(listingRequestsRef, listingRequestData);

            // Notify the lister of the listing.
            postNotification({
                userId: listing.labMember.id,
                title: "General Listing Request!",
                content: `${user.firstName} ${user.lastName} has requested a listing.`,
                url: `/manager/${listing.labId}/incoming-orders`,
            });

            // Notify the PI of the requester if the PI is not the requester. LOGIC IS WRONG.
            // if (lab.piId !== user.uid) {
            //     postNotification({
            //         userId: lab.piId,
            //         title: "Lab Listing Request!",
            //         content: `${user.firstName} ${user.lastName} has requested  a listing.`,
            //         url: `/manager/${listing.labId}`,
            //     });
            // }
            alert("Request sent successfully!");
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const sendMessageRequest = async (message) => {
        const combinedId =
            listing.labMember.id > user.uid
                ? listing.labMember.id + user.uid
                : user.uid + listing.labMember.id;
        const combinedListingId = combinedId + listingId;

        const date = Timestamp.now();

        try {
            const res = await getDoc(doc(db, "Chats", combinedListingId));
            const senderName = user.firstName + " " + user.lastName;
            const messageData = {
                message: message,
                senderName: senderName,
                senderId: user.uid,
                timestamp: date,
            };

            if (res.exists()) {
                await updateDoc(doc(db, "UserChats", user.uid), {
                    [combinedListingId + ".userInfo"]: {
                        uid: listing.labMember.id,
                        name: listing.labMember.label,
                    },
                    [combinedListingId + ".listingId"]: listingId,
                    [combinedListingId + ".dateCreated"]: date,
                    [combinedListingId + ".lastMessage"]: messageData,
                });

                await updateDoc(doc(db, "UserChats", listing.labMember.id), {
                    [combinedListingId + ".userInfo"]: {
                        uid: user.uid,
                        name: senderName,
                    },
                    [combinedListingId + ".listingId"]: listingId,
                    [combinedListingId + ".dateCreated"]: date,
                    [combinedListingId + ".lastMessage"]: messageData,
                });
            } else {
                await setDoc(doc(db, "UserChats", user.uid), {
                    [combinedListingId]: {
                        userInfo: {
                            uid: listing.labMember.id,
                            name: listing.labMember.label,
                        },
                        listingId: listingId,
                        dateCreated: date,
                        lastMessage: messageData,
                    },
                });

                await setDoc(doc(db, "UserChats", listing.labMember.id), {
                    [combinedListingId]: {
                        userInfo: {
                            uid: user.uid,
                            name: senderName,
                        },
                        listingId: listingId,
                        dateCreated: date,
                        lastMessage: messageData,
                    },
                });
            }

            const messageId = doc(
                collection(db, "Chats", combinedListingId, "messages")
            ).id;

            const uniqueMessageRef = doc(
                db,
                "Chats",
                combinedListingId,
                "messages",
                messageId
            );

            await setDoc(uniqueMessageRef, messageData);
            sendEmailNotification(
                senderName,
                listing.labMember.label,
                message,
                listing.listerEmail
            );
            postNotification({
                userId: listing.labMember.id,
                title: "New Message Request!",
                content: `${user.firstName} ${user.lastName} has sent you a message request.`,
                url: `/manager/${user.labId}/messages`,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const renderContent = (content) => {
        if (!content) return "";

        // Replace markdown-style links with HTML links
        // Add target="_blank" and rel="noopener noreferrer" for secure external links
        return content.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            (match, text, url) => {
                // Ensure URL is absolute
                const absoluteUrl =
                    url.startsWith("http://") || url.startsWith("https://")
                        ? url
                        : `https://${url}`;

                return `<a href="${absoluteUrl}" target="_blank" rel="noopener noreferrer" class="listing-content-link">${text}</a>`;
            }
        );
    };

    if (listingLoading || labLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="listing-detail">
                {isMobile ? (
                    // Mobile Layout
                    <>
                        <div className="listing-info">
                            <div className="listing-path">
                                <p className="listing-subpath">
                                    <a href="/">Home</a>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: "16px" }}
                                    />
                                    <a href="/labs">Browse Labs</a>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: "16px" }}
                                    />
                                    <a href={`/lab/${listing.labId}`}>
                                        {lab.name}
                                    </a>
                                </p>
                            </div>
                            <div className="listing-main-info">
                                <h2 className="listing-title">
                                    {listing.title}
                                </h2>
                                <div className="listing-badges">
                                    <div className="listing-badge-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "24px" }}
                                        />
                                    </div>
                                    <div className="listing-badge-lab">
                                        <GroupsIcon
                                            style={{ fontSize: "24px" }}
                                        />
                                        <span>{lab.name}</span>
                                    </div>
                                    {listing.isCollaborative && (
                                        <div className="listing-badge-collaborative">
                                            <HandshakeIcon
                                                style={{ fontSize: "24px" }}
                                            />
                                            <span>Collaborative</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="listing-card">
                            <div className="listing-card-image">
                                {listing.imgs.length > 1 && (
                                    <button onClick={() => decreaseImgIdx()}>
                                        <ArrowBackIosNewIcon
                                            style={{ fontSize: "12px" }}
                                        />
                                    </button>
                                )}
                                <img
                                    src={
                                        listing.imgs[currentImgIdx].url ||
                                        TestImage
                                    }
                                    alt=""
                                    className="listing-placeholder-image"
                                    style={{
                                        height:
                                            user && listing.isCollaborative
                                                ? "300px"
                                                : "450px",
                                    }}
                                />
                                {listing.imgs.length > 1 && (
                                    <button onClick={() => increaseImgIdx()}>
                                        <ArrowForwardIosIcon
                                            style={{ fontSize: "12px" }}
                                        />
                                    </button>
                                )}
                            </div>
                            <div className="listing-card-description">
                                <ReactMarkdown>
                                    {listing.description}
                                </ReactMarkdown>
                                {listing.relevantLink && (
                                    <div className="listing-card-link">
                                        <h3>Additional Information</h3>
                                        <a
                                            href={listing.relevantLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View Documentation
                                        </a>
                                    </div>
                                )}
                                <div className="listing-metadata">
                                    <div className="listing-metadata-created">
                                        <AccessTimeIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        Updated {listing.updatedAt}
                                    </div>
                                    {listing.location && (
                                        <div className="listing-metadata-location">
                                            <FmdGoodIcon
                                                style={{ fontSize: "16px" }}
                                            />
                                            {listing.location}
                                        </div>
                                    )}
                                </div>
                                <h3>Lab member in charge</h3>
                                <a href={`/profile/${listing.labMember.id}`}>
                                    {listing.labMember.label}
                                </a>
                            </div>
                            {listing.type !== "digital-good" &&
                                listing.isCollaborative && (
                                    <>
                                        {listing.type === "digital-good" ? (
                                            <div className="listing-card-price">
                                                Free access
                                            </div>
                                        ) : (
                                            <div className="listing-card-price">
                                                CA${dynamicPrice}{" "}
                                                <span className="listing-card-unit">
                                                    per{" "}
                                                    {listing.price.units ===
                                                    "other"
                                                        ? listing.price
                                                              .customUnits
                                                        : listing.price.units}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            {listing.type === "digital-good" && (
                                <div className="listing-card-digital">
                                    <div className="listing-card-licensing">
                                        <h3>Licensing Type</h3>
                                        <p>{listing.licensingType}</p>
                                    </div>
                                    {listing.retrievalMethods && (
                                        <div className="listing-card-retrieval">
                                            <h3>Access Method</h3>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: renderContent(
                                                        listing.retrievalMethods
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <button
                                className="listing-action-button listing-card-send-message"
                                onClick={() =>
                                    setIsMessageModalOpen(!isMessageModalOpen)
                                }
                                disabled={
                                    !user?.labId ||
                                    listing.labMember.id === user.uid
                                }
                            >
                                Message Lab Member
                            </button>
                            {listing.type !== "digital-good" &&
                                listing.isCollaborative && (
                                    <>
                                        <div className="listing-card-qty">
                                            <div
                                                className="listing-card-qty-subtract"
                                                onClick={decreaseQuantity}
                                            >
                                                -
                                            </div>
                                            <div className="listing-card-qty-input">
                                                Quantity: {quantity}{" "}
                                                {listing.price.units === "other"
                                                    ? listing.price.customUnits
                                                    : listing.price.units}
                                            </div>
                                            <div
                                                className="listing-card-qty-add"
                                                onClick={increaseQuantity}
                                            >
                                                +
                                            </div>
                                        </div>
                                        <button
                                            className="listing-action-button listing-card-add-to-cart"
                                            disabled={!user?.labId}
                                            // onClick={sendListingRequest}
                                        >
                                            Confirm Request
                                        </button>
                                    </>
                                )}
                        </div>
                    </>
                ) : (
                    // Desktop Layout (existing layout)
                    <>
                        <div className="listing-info">
                            <div className="listing-path">
                                <p className="listing-subpath">
                                    <a href="/">Home</a>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: "16px" }}
                                    />
                                    <a href="/labs">Browse Labs</a>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: "16px" }}
                                    />
                                    <a href={`/lab/${listing.labId}`}>
                                        {lab.name}
                                    </a>
                                </p>
                            </div>
                            <div className="listing-main-info">
                                <h2 className="listing-title">
                                    {listing.title}
                                </h2>
                                <div className="listing-badges">
                                    <div className="listing-badge-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "24px" }}
                                        />
                                    </div>
                                    <div className="listing-badge-lab">
                                        <GroupsIcon
                                            style={{ fontSize: "24px" }}
                                        />
                                        <span>{lab.name}</span>
                                    </div>
                                    {listing.isCollaborative && (
                                        <div className="listing-badge-collaborative">
                                            <HandshakeIcon
                                                style={{ fontSize: "24px" }}
                                            />
                                            <span>Collaborative</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="listing-description">
                                <ReactMarkdown>
                                    {listing.description}
                                </ReactMarkdown>
                                {listing.relevantLink && (
                                    <div className="listing-card-link">
                                        <h3>Additional Information</h3>
                                        <a
                                            href={listing.relevantLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View Documentation
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="listing-metadata">
                                <div className="listing-metadata-created">
                                    <AccessTimeIcon
                                        style={{ fontSize: "16px" }}
                                    />
                                    Updated {listing.updatedAt}
                                </div>
                                {listing.location && (
                                    <div className="listing-metadata-location">
                                        <FmdGoodIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        {listing.location}
                                    </div>
                                )}
                            </div>
                            <h3>Lab member in charge</h3>
                            <a href={`/profile/${listing.labMember.id}`}>
                                {listing.labMember.label}
                            </a>
                        </div>
                        <div className="listing-card">
                            <div className="listing-card-image">
                                {listing.imgs.length > 1 && (
                                    <button onClick={() => decreaseImgIdx()}>
                                        <ArrowBackIosNewIcon
                                            style={{ fontSize: "12px" }}
                                        />
                                    </button>
                                )}
                                <img
                                    src={
                                        listing.imgs[currentImgIdx].url ||
                                        TestImage
                                    }
                                    alt=""
                                    className="listing-placeholder-image"
                                    style={{
                                        height:
                                            user && listing.isCollaborative
                                                ? "300px"
                                                : "450px",
                                    }}
                                />
                                {listing.imgs.length > 1 && (
                                    <button onClick={() => increaseImgIdx()}>
                                        <ArrowForwardIosIcon
                                            style={{ fontSize: "12px" }}
                                        />
                                    </button>
                                )}
                            </div>
                            {listing.type !== "digital-good" &&
                                listing.isCollaborative && (
                                    <>
                                        {listing.type === "digital-good" ? (
                                            <div className="listing-card-price">
                                                Free access
                                            </div>
                                        ) : (
                                            <div className="listing-card-price">
                                                CA${dynamicPrice}{" "}
                                                <span className="listing-card-unit">
                                                    per{" "}
                                                    {listing.price.units ===
                                                    "other"
                                                        ? listing.price
                                                              .customUnits
                                                        : listing.price.units}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            {listing.type === "digital-good" && (
                                <div className="listing-card-digital">
                                    <div className="listing-card-licensing">
                                        <h3>Licensing Type</h3>
                                        <p>{listing.licensingType}</p>
                                    </div>
                                    {listing.retrievalMethods && (
                                        <div className="listing-card-retrieval">
                                            <h3>Access Method</h3>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: renderContent(
                                                        listing.retrievalMethods
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <button
                                className="listing-action-button listing-card-send-message"
                                onClick={() =>
                                    setIsMessageModalOpen(!isMessageModalOpen)
                                }
                                disabled={
                                    !user?.labId ||
                                    listing.labMember.id === user.uid
                                }
                            >
                                Message Lab Member
                            </button>
                            {listing.type !== "digital-good" &&
                                listing.isCollaborative && (
                                    <>
                                        <div className="listing-card-qty">
                                            <div
                                                className="listing-card-qty-subtract"
                                                onClick={decreaseQuantity}
                                            >
                                                -
                                            </div>
                                            <div className="listing-card-qty-input">
                                                Quantity: {quantity}{" "}
                                                {listing.price.units === "other"
                                                    ? listing.price.customUnits
                                                    : listing.price.units}
                                            </div>
                                            <div
                                                className="listing-card-qty-add"
                                                onClick={increaseQuantity}
                                            >
                                                +
                                            </div>
                                        </div>
                                        <button
                                            className="listing-action-button listing-card-add-to-cart"
                                            disabled={!user?.labId}
                                            // onClick={sendListingRequest}
                                        >
                                            Confirm Request
                                        </button>
                                    </>
                                )}
                        </div>
                    </>
                )}
            </div>
            <ModalComponent
                title={`Send a message to ${listing.labMember.label}`}
                isOpen={isMessageModalOpen}
                toggle={() => setIsMessageModalOpen(!isMessageModalOpen)}
                submitText="Send Message Request"
                onSubmit={() => {
                    sendMessageRequest(message);
                    navigate(`/manager/${user.labId}/messages`);
                }}
            >
                <textarea
                    className="input"
                    type="text"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </ModalComponent>
        </>
    );
};

export default Listing;
