import React, { useEffect, useState } from "react";
import { fetchDelete, fetchGet } from "../../services/data-service";
import "../../styles/Admin/Admin.css";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../../components";

const AdminLabs = () => {
    const [labs, setLabs] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [labToDelete, setLabToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("fetching labs");
        fetchGet("/admin/labs", true).then((labs) => setLabs(labs));
    }, []);

    const deleteLab = (labId) => {
        fetchDelete(`/admin/lab/${labId}`, true).then(() => {
            setLabs(labs.filter((lab) => lab.id !== labId));
        });
    };

    return (
        <div>
            <h1 style={{ textAlign: "center", fontSize: "24px" }}>
                Admin Labs
            </h1>
            {labs.map((lab) => (
                <div key={lab.id} className="lab-registration-container">
                    <div className="lab-registration-component">
                        <h2>{lab.name}</h2>
                        <div className="lab-registration-actions">
                            <button
                                onClick={() => {
                                    console.log(`/manager/${lab.id}/overview`);
                                    navigate(`/manager/${lab.id}/overview`);
                                }}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => {
                                    setLabToDelete(lab.id);
                                    setIsDeleteModalOpen(true);
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            <ModalComponent
                title="Confirm Delete Lab"
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                submitText="Confirm"
                onSubmit={() => {
                    deleteLab(labToDelete);
                    setIsDeleteModalOpen(false);
                }}
            >
                Are you sure you want to delete this lab?
            </ModalComponent>
        </div>
    );
};

export default AdminLabs;
