import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { VerifyEmailBanner } from "../WarningBanner";
import Loading from "../Loading";

export default function UnverifiedUserRoute({ children }) {
    const { userId } = useParams();
    const [access, setAccess] = useState("loading");
    const { user, loading } = UserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user.uid === userId && !user?.emailVerified) {
            setAccess("unverified");
        } else {
            setAccess("verified");
        }
    }, [navigate, user, userId, loading]);

    if (access === "loading") return <Loading />;
    if (access === "unverified")
        return (
            <>
                <VerifyEmailBanner />
                {children}
            </>
        );
    if (access === "verified") return children;
    return null;
}
