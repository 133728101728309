import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, ModalComponent, Spinner } from "../../components";
import { UserAuth } from "../../context/auth-context";
import { TypeBadge } from "../../helpers/ListingHelpers";
import { fetchDelete, fetchGet } from "../../services/data-service";
import "../../styles/LabManagerTabs/ListingsManager.css";

const TimePeriodDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("30 days");
    const dropdownRef = useRef(null);

    const timePeriods = [
        "30 days",
        "60 days",
        "90 days",
        "6 months",
        "1 year",
        "All time",
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="time-period-dropdown">
            <button onClick={toggleDropdown} className="time-period-button">
                <AccessTimeIcon />
                {selectedTimePeriod}
            </button>
            {isOpen && (
                <ul className="time-period-list">
                    {timePeriods.map((timePeriod) => (
                        <li
                            key={timePeriod}
                            onClick={() => handleSelect(timePeriod)}
                            className="time-period-item"
                        >
                            {timePeriod}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const ListingsManager = () => {
    const [listings, setListings] = useState([]);
    const [listingToDelete, setListingToDelete] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { labId } = useParams();
    const { user } = UserAuth();
    const [activeTab, setActiveTab] = useState("all");
    const [canEdit, setCanEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [isOpen, setIsOpen] = useState(true);
    // const [favorites, setFavorites] = useState(new Array(5).fill(false));

    const toggleModal = () => {
        if (isModalOpen) {
            setListingToDelete(null);
        }
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        const fetchListings = async () => {
            const listings = await fetchGet(`/labs/${labId}/listings`, true);
            const member = await fetchGet(
                `/labs/${labId}/members/${user.uid}`,
                true
            );

            if (member?.role === "pi" || member?.role === "manager") {
                setCanEdit(true);
            }
            setListings(listings);
        };

        fetchListings();
    }, []);

    const deleteListing = async (id) => {
        setLoading(true);
        try {
            const res = await fetchDelete(`/listings/${id}`);
            window.location.reload();
        } catch (error) {
            setLoading(false);
            console.error("Error deleting listing or images folder: ", error);
        }
    };

    const filterListings = (tab) => {
        switch (tab) {
            case "all":
                return listings;
            case "items":
                return listings.filter(
                    (listing) => listing.type === "specific-item"
                );
            case "equipment":
                return listings.filter(
                    (listing) => listing.type === "equipment-rental"
                );
            case "services":
                return listings.filter((listing) => listing.type === "service");

            case "collaborations":
                return listings.filter(
                    (listing) => listing.isCollaborative === true
                );
            case "digital-goods":
                return listings.filter(
                    (listing) => listing.type === "digital-good"
                );
            default:
                return [];
        }
    };

    const EditableListings = ({ listings }) => {
        return (
            <>
                {listings.map((listing, _) => (
                    <div
                        key={listing.id}
                        className="listings-manager-listing"
                        onClick={() => {
                            if (listing.type === "equipment-rental")
                                navigate(`/listingrental/${listing.id}`);
                            else {
                                navigate(`/listing/${listing.id}`);
                            }
                        }}
                    >
                        <img
                            src={listing.img}
                            className="listings-manager-listing-img"
                            alt=""
                        />
                        <div className="listings-manager-listing-info">
                            <div className="listings-manager-listing-name">
                                {listing.title}
                            </div>
                            <div className="listings-manager-listing-description">
                                {listing.description}
                            </div>
                            <div className="listings-manager-listing-footer">
                                <div className="listings-manager-listing-type">
                                    <TypeBadge
                                        type={listing.type}
                                        style={{ fontSize: "20px" }}
                                    />
                                </div>

                                {canEdit && (
                                    <div className="listings-manager-listing-buttons">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                navigate(
                                                    `/manager/${labId}/listings/edit-listing/${listing.id}`
                                                );
                                            }}
                                        >
                                            <EditIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setListingToDelete({
                                                    id: listing.id,
                                                    title: listing.title,
                                                });
                                                toggleModal();
                                            }}
                                        >
                                            <DeleteIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    if (!listings) {
        return <Loading />;
    }

    return (
        <div className="listings-manager-container">
            <div className="listings-manager-filter">
                <h1 className="listings-manager-title">
                    Technologies & Resources
                </h1>
                {canEdit && (
                    <button
                        className="listings-manager-create-listing"
                        onClick={() =>
                            navigate(
                                `/manager/${labId}/listings/create-listing`
                            )
                        }
                    >
                        <AddIcon />
                        New Listing
                    </button>
                )}
            </div>

            <div className="listings-manager-tabs">
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "all" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("all")}
                >
                    All
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "collaborations" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("collaborations")}
                >
                    Collaborative
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "items" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("items")}
                >
                    Specific Item
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "equipment" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("equipment")}
                >
                    Equipment
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "services" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("services")}
                >
                    Service
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "digital-goods" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("digital-goods")}
                >
                    Digital Good
                </button>
            </div>

            <div className="listings-manager-content">
                <div className="listings-manager-grid">
                    <EditableListings listings={filterListings(activeTab)} />
                </div>
            </div>

            <ModalComponent
                isOpen={isModalOpen}
                toggle={toggleModal}
                title="Delete Listing"
                submitText={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                        }}
                    >
                        {loading && <Spinner />}
                        Delete
                    </div>
                }
                onSubmit={() => deleteListing(listingToDelete.id)}
            >
                <div style={{ display: "flex", gap: "5px" }}>
                    Are you sure you want to delete
                    <span style={{ fontWeight: 600 }}>
                        {listingToDelete?.title}
                    </span>
                    ?
                </div>
            </ModalComponent>
        </div>
    );
};

export default ListingsManager;
