import { useState, useEffect } from "react";
import { fetchGet } from "../services/data-service";
import { useQuery } from "@tanstack/react-query";
import { fetchLabs, LabCacheParams } from "../services/lab-api";
import { fetchListings, ListingCacheParams } from "../services/listing-api";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase-config";
import {
    Newspaper as NewspaperIcon,
    MenuBook as MenuBookIcon,
    SmartToy as SmartToyIcon,
    AccountBalance as AccountBalanceIcon,
    Science as ScienceIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import {
    collection,
    orderBy,
    getDocs,
    query,
    Timestamp,
    limit,
    startAfter,
} from "firebase/firestore";
import "../styles/Newsfeed.css";
import { CircularProgress } from "@mui/material";
import { Loading } from "../components";

const ITEMS_PER_PAGE = 5;

const NewsImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!images || images.length === 0) return null;
    if (images.length === 1) {
        return <img src={images[0]} alt="" className="nf-content-image" />;
    }

    return (
        <div className="nf-carousel-container">
            <button
                className="nf-carousel-arrow nf-left"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === 0 ? images.length - 1 : prev - 1
                    );
                }}
            >
                <ArrowBackIosNewIcon />
            </button>
            <img
                src={images[currentIndex]}
                alt=""
                className="nf-content-image"
            />
            <button
                className="nf-carousel-arrow nf-right"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === images.length - 1 ? 0 : prev + 1
                    );
                }}
            >
                <ArrowForwardIosIcon />
            </button>
        </div>
    );
};

const Newsfeed = () => {
    const navigate = useNavigate();
    const [displayedLabs, setDisplayedLabs] = useState([]);
    const [feedItems, setFeedItems] = useState([]);
    const [currentListingIndex, setCurrentListingIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const { data: labs, isLoading: labsLoading } = useQuery({
        queryKey: ["labs", { status: "approved" }],
        queryFn: fetchLabs,
        ...LabCacheParams,
    });

    const { data: listings, isLoading: listingsLoading } = useQuery({
        queryKey: ["listings"],
        queryFn: fetchListings,
        ...ListingCacheParams,
    });

    const fetchSuggestedConnections = async ({ queryKey }) => {
        const [_, labs] = queryKey;
        const allMembers = [];
        for (const lab of labs) {
            const members = await fetchGet(`/labs/${lab.id}/members`, false);
            for (const member of members.members) {
                member.institutionName = lab.institutionName;
                member.labName = lab.name;
                member.labId = lab.id;
            }
            allMembers.push(...members.members);
        }

        const shuffled = [...allMembers].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 5);
    };

    const {
        data: suggestedConnections,
        isLoading: suggestedConnectionsLoading,
    } = useQuery({
        queryKey: ["connections", labs],
        queryFn: fetchSuggestedConnections,
        enabled: !!labs,
        ...LabCacheParams,
    });

    const formatDisplayDate = (timestamp) => {
        if (!timestamp) return "Unknown Date";

        try {
            // Handle Firestore Timestamp object with _seconds and _nanoseconds
            if (timestamp._seconds) {
                const date = new Date(timestamp._seconds * 1000);
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            // Handle Firestore Timestamp with toDate method
            if (timestamp?.toDate) {
                return timestamp.toDate().toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            // Handle regular Date object or timestamp number
            const date = new Date(timestamp);
            if (!isNaN(date.getTime())) {
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            return "Unknown Date";
        } catch (error) {
            console.error("Error formatting date:", error, timestamp);
            return "Unknown Date";
        }
    };

    const fetchFeedItems = async (isInitial = false) => {
        if (isLoading || (!isInitial && !hasMore)) return;

        try {
            setIsLoading(true);

            // Create the initial query
            let newsQuery = query(
                collection(db, "News"),
                orderBy("timestamp", "desc"),
                limit(ITEMS_PER_PAGE)
            );

            // If not initial load and we have a last document, start after it
            if (!isInitial && lastDoc) {
                newsQuery = query(
                    collection(db, "News"),
                    orderBy("timestamp", "desc"),
                    startAfter(lastDoc),
                    limit(ITEMS_PER_PAGE)
                );
            }

            const newsSnapshot = await getDocs(newsQuery);

            // Store the last document for next pagination
            const lastVisible = newsSnapshot.docs[newsSnapshot.docs.length - 1];
            setLastDoc(lastVisible);

            // Check if we have more items to load
            setHasMore(newsSnapshot.docs.length === ITEMS_PER_PAGE);

            const newsList = [];

            // Process news items
            for (const doc of newsSnapshot.docs) {
                const newsData = {
                    id: doc.id,
                    ...doc.data(),
                    type: "news",
                };
                const labData = await fetchGet(
                    `/labs/${newsData.labId}`,
                    false
                );
                newsData.labLogo = labData.logo;
                newsData.labName = labData.name;

                if (newsData.imageRef) {
                    try {
                        const imageUrl = await getDownloadURL(
                            ref(storage, newsData.imageRef)
                        );
                        newsData.imageUrl = imageUrl;
                    } catch (error) {
                        console.error("Error fetching news image:", error);
                        newsData.imageUrl = null;
                    }
                }
                newsList.push(newsData);
            }

            // Fetch all publications that are set to display in lab profiles
            const publicationsQuery = query(collection(db, "Publications"));
            const publicationsSnapshot = await getDocs(publicationsQuery);
            const publicationsList = [];

            // Process publications
            for (const doc of publicationsSnapshot.docs) {
                const pubData = {
                    id: doc.id,
                    ...doc.data(),
                    type: "publication",
                };

                // Only include publications that are set to display in any lab profile
                if (pubData.displayInLabs?.length > 0) {
                    // Get date 3 months ago
                    const threeMonthsAgo = new Date();
                    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

                    // Check publication date
                    const pubDate =
                        pubData.publicationDate instanceof Timestamp
                            ? pubData.publicationDate.toDate()
                            : pubData.publicationDate?._seconds
                            ? new Date(pubData.publicationDate._seconds * 1000)
                            : new Date(pubData.publicationDate);

                    if (pubDate >= threeMonthsAgo) {
                        // For each lab that should display this publication
                        for (const labId of pubData.displayInLabs) {
                            try {
                                const labData = await fetchGet(
                                    `/labs/${labId}`,
                                    false
                                );
                                publicationsList.push({
                                    ...pubData,
                                    labId,
                                    labName: labData.name,
                                    labLogo: labData.logo,
                                    timestamp:
                                        pubData.displayInProfileTimestamp,
                                    author: pubData.creatorName,
                                    authorId: pubData.creatorId,
                                    title: pubData.title,
                                    content: pubData.description,
                                    imageUrl: pubData.images?.[0] || null,
                                });
                            } catch (error) {
                                console.error(
                                    "Error fetching lab data for publication:",
                                    error
                                );
                            }
                        }
                    }
                }
            }

            // Combine all items and sort by timestamp
            const allItems = [...newsList, ...publicationsList].sort((a, b) => {
                const getTime = (item) => {
                    const timestamp =
                        item.type === "publication"
                            ? item.displayInProfileTimestamp
                            : item.timestamp;
                    return (
                        timestamp?._seconds * 1000 ||
                        timestamp?.toDate?.().getTime() ||
                        0
                    );
                };

                return getTime(b) - getTime(a);
            });

            // Update feedItems based on whether it's initial load or not
            setFeedItems((prevItems) =>
                isInitial ? allItems : [...prevItems, ...allItems]
            );
        } catch (error) {
            console.error("Error fetching feed items:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Initial load
    useEffect(() => {
        fetchFeedItems(true);
    }, []);

    const SCROLL_DELAY = 500;

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.documentElement.scrollHeight - 1000 &&
                !isLoading &&
                hasMore
            ) {
                // Clear any existing timeout
                if (timeoutId) clearTimeout(timeoutId);

                // Set a new timeout
                timeoutId = setTimeout(() => {
                    fetchFeedItems(false);
                }, SCROLL_DELAY);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isLoading, hasMore, lastDoc]);

    useEffect(() => {
        const updateDisplayedListings = () => {
            setCurrentListingIndex((prevIndex) => {
                // Calculate how many complete sets of 3 we can show
                const maxSets = Math.floor(listings.length / 3);
                const nextIndex = prevIndex + 3;

                // If we've reached or exceeded the last complete set, return to start
                if (prevIndex >= (maxSets - 1) * 3) {
                    return 0;
                }
                return nextIndex;
            });
        };

        if (listings?.length > 3) {
            const interval = setInterval(updateDisplayedListings, 10000);
            return () => clearInterval(interval);
        }
    }, [listings?.length]);

    const getVisibleListings = () => {
        if (listings.length <= 3) return listings;

        // Ensure we always have 3 items
        return [
            listings[currentListingIndex],
            listings[currentListingIndex + 1],
            listings[currentListingIndex + 2],
        ];
    };

    useEffect(() => {
        const updateDisplayedLabs = () => {
            const shuffled = [...labs].sort(() => 0.5 - Math.random());
            setDisplayedLabs(shuffled.slice(0, 3));
        };

        if (labs?.length > 0) {
            updateDisplayedLabs();
            const interval = setInterval(updateDisplayedLabs, 10000);
            return () => clearInterval(interval);
        }
    }, [labs]);

    const handleListingClick = (listing) => {
        if (
            listing.type === "equipment-rental" ||
            listing.type === "training-expertise"
        ) {
            navigate(`/listingrental/${listing.id}`);
        } else {
            navigate(`/listing/${listing.id}`);
        }
    };

    const renderContent = (content) => {
        if (!content) return "";

        // Replace markdown links with actual links
        return content.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            (match, text, url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="nf-content-link">${text}</a>`;
            }
        );
    };

    if (labsLoading || listingsLoading || suggestedConnectionsLoading)
        return <Loading />;

    return (
        <div className="nf-newsfeed-container">
            <main className="nf-newsfeed-main">
                <div className="nf-newsfeed-grid">
                    <div className="nf-feed-column">
                        <h2 className="nf-section-title">Giant Board</h2>
                        {feedItems.map((item, index) => (
                            <div key={index} className="nf-feed-card">
                                <div className="nf-card-header">
                                    <div className="nf-header-content">
                                        <div
                                            className="nf-avatar"
                                            onClick={() =>
                                                navigate(`/lab/${item.labId}`)
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src={item.labLogo}
                                                alt=""
                                                className="nf-avatar-image"
                                            />
                                        </div>
                                        <div className="nf-header-text">
                                            <h3>{item.title}</h3>
                                            <div className="nf-author-info">
                                                {item.author && (
                                                    <p
                                                        className="nf-subtitle"
                                                        onClick={() =>
                                                            navigate(
                                                                `/profile/${item.authorId}`
                                                            )
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item.author}
                                                    </p>
                                                )}
                                                <span
                                                    className="nf-lab-name"
                                                    onClick={() =>
                                                        navigate(
                                                            `/lab/${item.labId}`
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    @{item.labName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nf-badge">
                                        {item.type === "news" ? (
                                            <>
                                                <NewspaperIcon /> News
                                            </>
                                        ) : (
                                            <>
                                                <MenuBookIcon /> Publication
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="nf-card-content">
                                    {item.type === "publication" &&
                                        item.imageUrl && (
                                            <NewsImageCarousel
                                                images={[item.imageUrl]}
                                            />
                                        )}
                                    <p
                                        style={{
                                            marginTop:
                                                item.type === "publication" &&
                                                item.imageUrl
                                                    ? "16px"
                                                    : "0",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: renderContent(item.content),
                                        }}
                                    />
                                    {item.type === "publication" && (
                                        <p className="nf-publication-meta">
                                            Published on{" "}
                                            {new Date(
                                                item.publicationDate
                                            ).toLocaleDateString()}{" "}
                                            in
                                            <span className="nf-publication-journal">
                                                {" "}
                                                {item.journal}
                                            </span>
                                        </p>
                                    )}
                                    {item.type === "news" && (
                                        <NewsImageCarousel
                                            images={
                                                item.imageUrls ||
                                                (item.imageUrl
                                                    ? [item.imageUrl]
                                                    : [])
                                            }
                                        />
                                    )}
                                </div>
                                <div className="nf-card-footer">
                                    <span>
                                        {formatDisplayDate(
                                            item.type === "publication"
                                                ? item.displayInProfileTimestamp
                                                : item.timestamp
                                        )}
                                    </span>
                                    {item.type === "publication" && (
                                        <div className="nf-publication-links">
                                            <a
                                                href={item.htmlLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="nf-publication-link"
                                            >
                                                Article HTML
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="nf-sidebar-column">
                        {/* Marketplace Card */}
                        <div className="nf-sidebar-card">
                            <h3
                                className="nf-marketplace-title"
                                onClick={() => navigate("/listings")}
                            >
                                Accessible Resources & Technologies
                            </h3>
                            <div className="nf-marketplace-items-container">
                                <div
                                    className="nf-marketplace-items-wrapper"
                                    style={{
                                        transform: `translateY(-${
                                            Math.floor(
                                                currentListingIndex / 3
                                            ) * 330
                                        }px)`,
                                    }}
                                >
                                    {listings.map((item, index) => (
                                        <div
                                            key={index}
                                            className="nf-marketplace-item"
                                            onClick={() =>
                                                handleListingClick(item)
                                            }
                                        >
                                            <img
                                                src={item.img}
                                                alt={item.title}
                                                className="nf-marketplace-image"
                                            />
                                            <div className="nf-marketplace-item-content">
                                                <h4>{item.title}</h4>
                                                <p className="nf-lab-name">
                                                    {item.labName}
                                                </p>
                                                <p className="nf-price">
                                                    {item.type ===
                                                    "digital-good"
                                                        ? "Free access"
                                                        : `$${
                                                              item.price.value
                                                          } per ${
                                                              item.price
                                                                  .customUnits ||
                                                              item.price.units
                                                          }`}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Suggested Labs Card */}
                        <div className="nf-sidebar-card">
                            <h3
                                className="nf-marketplace-title"
                                onClick={() => navigate("/labs")}
                            >
                                Labs to Explore
                            </h3>
                            <ul className="nf-suggestion-list">
                                {displayedLabs.map((lab) => (
                                    <li
                                        key={lab.id}
                                        className="nf-suggestion-item"
                                        onClick={() =>
                                            navigate(`/lab/${lab.id}`)
                                        }
                                    >
                                        <div className="nf-lab-avatar">
                                            <img
                                                src={lab.logo}
                                                alt={lab.name}
                                            />
                                        </div>
                                        <div className="nf-suggestion-content">
                                            <p className="nf-lab-title">
                                                {lab.name}
                                            </p>
                                            <div className="nf-lab-details">
                                                <span>
                                                    <AccountBalanceIcon
                                                        style={{
                                                            fontSize: "12px",
                                                        }}
                                                    />
                                                    {lab.institutionName}
                                                </span>
                                                <span>
                                                    <ScienceIcon
                                                        style={{
                                                            fontSize: "12px",
                                                        }}
                                                    />
                                                    {lab.departmentName}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Suggested Connections Card */}
                        <div className="nf-sidebar-card">
                            <h3 className="nf-marketplace-title">
                                Suggested Connections
                            </h3>
                            <ul className="nf-suggestion-list">
                                {suggestedConnections.map((connection) => (
                                    <li
                                        key={connection.id}
                                        className="nf-suggestion-item"
                                        onClick={() =>
                                            navigate(
                                                `/profile/${connection.id}`
                                            )
                                        }
                                    >
                                        <div className="nf-connection-avatar">
                                            <img
                                                src={
                                                    connection.profilePicture ||
                                                    ""
                                                }
                                                alt={`${connection.firstName} ${connection.lastName}`}
                                                onError={(e) => {
                                                    e.target.style.display =
                                                        "none";
                                                }}
                                            />
                                        </div>
                                        <div className="nf-suggestion-content">
                                            <p className="nf-connection-name">
                                                {connection.firstName}{" "}
                                                {connection.lastName}
                                            </p>
                                            <p className="nf-connection-lab">
                                                {connection.labName}
                                            </p>
                                            <p className="nf-connection-institution">
                                                {connection.institutionName}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {hasMore && (
                    <div className={`nf-loading ${isLoading ? "visible" : ""}`}>
                        <CircularProgress size={24} />
                        <span>Loading more items...</span>
                    </div>
                )}

                {!hasMore && (
                    <div className="nf-no-more">No more items to load</div>
                )}
            </main>

            <button className="nf-add-button">
                <SmartToyIcon />
            </button>
        </div>
    );
};

export default Newsfeed;
